import { useMemo } from "react";
import { LayoutChangeEvent } from "react-native";
import useStateRef from "src/hooks/useStateRef";

const useAutoMinDim = (avoidShrink: boolean) => {
	const [autoMinDim, setAutoMinDim, autoMinDimRef] = useStateRef({
		width: 0,
		height: 0,
	});
	const handleLayoutChange = useMemo(
		() =>
			avoidShrink
				? (event: LayoutChangeEvent) => {
						const { width, height } = event.nativeEvent.layout;
						if (
							width > autoMinDimRef.current.width ||
							height > autoMinDimRef.current.height
						) {
							setAutoMinDim({
								width: Math.max(width, autoMinDimRef.current.width),
								height: Math.max(height, autoMinDimRef.current.height),
							});
						}
					}
				: undefined,
		[avoidShrink, autoMinDimRef, setAutoMinDim],
	);

	return {
		autoMinWidth: autoMinDim.width,
		autoMinHeight: autoMinDim.height,
		handleLayoutChange,
	};
};
export default useAutoMinDim;
