import { useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Defs, RadialGradient, Rect, Stop, Svg } from "react-native-svg";
import Noise from "src/components/Noise/Noise";
import native from "src/constants/native";
import SvgSilverBorder from "./SvgSilverBorder";

// Native and web just show up differently, so we have to have different values to make it look good on both
const radius = native ? "80%" : "120%";
const verticalScale = native ? 1.2 : 0.5;
const cy = `${50 / verticalScale}%`;
const gradientTransform = `scale(1, ${verticalScale})`;

const PrimaryBackground = ({
	borderRadius,
	containerStyle,
}: {
	borderRadius: number;
	containerStyle?: StyleProp<ViewStyle>;
}) => {
	const [dim, setDim] = useState({ width: 0, height: 0 });
	const { width, height } = dim;

	return (
		<View
			onLayout={(event) => {
				const { width, height } = event.nativeEvent.layout;
				setDim({ width, height });
			}}
			style={[
				{
					position: "absolute",
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					overflow: "hidden",
				},
				containerStyle,
				{
					borderRadius,
				},
			]}
		>
			{!!(width && height) && (
				<Svg viewBox={`0 0 ${width} ${height}`}>
					<Defs>
						<RadialGradient
							id="grad"
							cx="105%"
							cy={cy}
							r={radius}
							gradientUnits="userSpaceOnUse"
							gradientTransform={gradientTransform}
						>
							<Stop stopColor="#AEC2FF" />
							<Stop offset="0.2" stopColor="#8394C6" />
							<Stop offset="0.4" stopColor="#66739A" />
							<Stop offset="0.6" stopColor="#414A63" />
							<Stop offset="0.8" stopColor="#232836" />
							<Stop offset="1" stopColor="#101218" />
						</RadialGradient>
					</Defs>

					<Rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
					<SvgSilverBorder borderRadius={borderRadius} />
				</Svg>
			)}
			<Noise
				dim={dim}
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
				}}
				opacity={0.1}
			/>
		</View>
	);
};
export default PrimaryBackground;
